<template>
  <div class="w-full">
    <div class="relative">
      <slot :id="id" />
      <label
        :for="id"
        class="pointer-events-none absolute left-3 top-1 w-full truncate pr-12 text-3xs font-medium transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-sm peer-focus:top-1 peer-focus:text-3xs peer-has-[option[value='']:checked]:top-3 peer-has-[option[value='']:checked]:text-sm"
        :class="{
          'text-gray-300': !error,
          'text-red-600': !!error,
        }"
      >
        {{ label }} {{ required ? '*' : '' }}
      </label>
      <slot name="icon">
        <ClientOnly>
          <Component
            :is="iconComponent"
            v-bind="$attrs"
            v-if="icon"
            class="absolute right-3 top-4 h-4 w-auto"
            :class="{
              'text-white': dark,
            }"
          />
        </ClientOnly>
      </slot>
    </div>
    <span
      v-if="typeof error === 'string'"
      class="inline-flex px-3 text-4xs text-red-700"
    >
      {{ error }}
    </span>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  id?: string
  label: string
  icon?: string
  error?: string | boolean
  dark: boolean
  required: boolean
}>()

const iconComponent = computed(() => {
  if (props.icon) {
    return resolveComponent('icon-' + props.icon)
  }
  return null
})
</script>
